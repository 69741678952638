import React, { useEffect, useRef } from "react";
import {
  Buttons,
  CardsSection,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, Grid } from "@mui/material";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Freequate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const contactform = [
    { placeholder: "Email Address", name: "email", type: "email" },
    { placeholder: "Your Website URL", name: "url", type: "text" },
    { placeholder: "Your Full Name", name: "from_name", type: "text" },
    { placeholder: "Phone Number", name: "phone", type: "text" },
  ];
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_euk05dp",
        "template_0v9kktd",
        form.current,
        "-ER35YKpfAl5T5gD8"
      )
      .then(
        (result) => {
          toast.success("Thank you for contact us", {
            position: "top-right",
            autoClose: 3000, // Duration in milliseconds
          });
        },
        (error) => {
          toast.error("hai", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="Free Quote"
        txt2="Welcome to Vedaham business solutions, your gateway to elevated online visibility. Let's propel your digital presence forward with our tailored SEO expertise.​"
        imges="https://ik.imagekit.io/41cnxfw1v/vbsimages/faqcontactus/free-quote.png?updatedAt=1712849110540"
      />
      <Section2
        text1="SEO Quote Request Form"
        pt="5%"
        text2={
          <>
            Welcome to Vedaham business solutions, where we believe in enhancing
            your online presence through strategic SEO solutions. We understand
            the importance of visibility in today's digital landscape, and our
            team is dedicated to optimizing your website to its fullest
            potential. Please fill out the following quote request form to help
            us better understand your needs and tailor our services to meet your
            objectives. Let's embark on this journey together towards greater
            online success.
          </>
        }
      />
      <Box sx={{ p: "6%" }}>
        <form action="" ref={form} onSubmit={sendEmail}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "2%",
              backgroundColor: "whitesmoke",
              borderRadius: "10px",
            }}
          >
            {contactform.map((data, index) => (
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sx={{ pr: "16px", pb: "16px" }}
              >
                <input
                  fullWidth
                  type={data.type}
                  // name="name"
                  name={data.name}
                  placeholder={data.placeholder}
                  required
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "none",

                    backgroundColor: "#FDFEFF",
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} sx={{ pr: "16px", pb: "16px" }}>
              <Button
                variant="contained"
                type="submit"
                className="button1"
                sx={{
                  bgcolor: "#BD0F65",
                  color: "#fff",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                Send Message
              </Button>
       
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default Freequate;
