import React, { useEffect } from "react";
import {
  AllCards,
  CardsSection,
  MetaComponent,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import Webdesign from "./Webdesign";
const Webdevlopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>

           <MetaComponent dynamicTitle="Web Development Company UAE & Web UI/UX Design Agency in Dubai" 
           dynamicKeywords="Web Development Company UAE & Web UI/UX Design Agency in Dubai "
            dynamicDescription="Create website to introduce all your products and services to the world. Vedaham Business Solutions provide you with best UI/UX designs that can make user navigation interesting. " /> 
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1={<>Web Development & Web Design</>}
        txt2="
        
Elevate your online presence with Vedaham Business Solutions' specialized web development services, crafting visually stunning and highly functional designs.​"
        imges='https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development%20(1).9777fa711378e0f94ead.png?updatedAt=1712844353574'
      />
      <Section2
        pt="5%"
        text1="Key Features of Our Web Development Services:"
        text2={
          <>
            At Vedaham, we understand the significance of a strong online
            presence in today's competitive market. Our web development services
            are tailored to meet the diverse needs of our clients, whether they
            require a static website to establish their online presence or a
            dynamic site with interactive features.
          </>
        }
      />
      {/****************************************8 */}
      <Grid
        container
        spacing={2}
        sx={{
          p: "6%",
        }}
      >
        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={6}>
            <AllCards
              boxShadow="none"
              image={data.image}
              height="50px"
              width="50px"
              backgroundColor="transparent"
              boxShadow1="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              // txt2textAlign="left"
            />
          </Grid>
        ))}
      </Grid>

      {/****************************************8 */}
      <Grid
        container
        spacing={5}
        sx={{ pt: "1%", pl: "6%", pr: "6%", pb: "6%" }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Web Streamlining Strategy</>}
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    For Vedaham Business Solution, a Web Streamlining Strategy
                    in web development involves optimizing website architecture
                    and content to improve search engine visibility and enhance
                    user experience, ultimately driving organic traffic and
                    boosting online presence for their clients. By implementing
                    SEO best practices and user-centric design principles,
                    Vedaham Business Solution ensures their web solutions
                    effectively attract and engage target audiences,
                    contributing to the success and growth of their clients'
                    businesses.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>
            {Listcontent.map((data, index) => (
              <Grid item lg={6} md={12} xs={12} sm={12} key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{ backgroundColor: "transparent" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <ListItemIcon>
                      <img src={data.listimg} alt="" />
                    </ListItemIcon>
                    <TypographyText
                      Typography={data.listtxt}
                      color="#1E1666"
                      fontSize="20px"
                      fontWeight="600"
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-process-img.png?updatedAt=1712844353524"
            alt=""
            width="100%"
          />
        </Grid>
      </Grid>

      <Webdesign />
    </>
  );
};

export default Webdevlopment;
const Data = [
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-service-new-icon1.png?updatedAt=1712844353529",
    Typography1: "Graphic Interface",
    Typography2:
      "Vedaham Business Solution prioritizes a visually appealing and intuitive user interface, ensuring clients can navigate their platforms effortlessly through thoughtfully designed layouts and visually engaging elements.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-service-new-icon2.png?updatedAt=1712844353788",
    Typography1: "Bootstrapping",
    Typography2:
      "Vedaham Business Solution ensures that their solutions are optimized for diverse screen sizes and devices, guaranteeing a seamless user experience whether accessed from desktops, laptops, tablets, or smartphones.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-service-new-icon3.png?updatedAt=1712844353667",
    Typography1: "Screen Optimized",
    Typography2:
      "Vedaham Business Solution ensures that their solutions are optimized for diverse screen sizes and devices, guaranteeing a seamless user experience whether accessed from desktops, laptops, tablets, or smartphones.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-service-new-icon4.png?updatedAt=1712844355024",
    Typography1: "Content Update",
    Typography2:
      "Vedaham Business Solution empower clients with easy-to-use content management systems, enabling them to effortlessly update and manage website content, including text, images, and multimedia elements, without relying on extensive technical expertise.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-service-new-icon5.png?updatedAt=1712844355047",
    Typography1: "Application Security",
    Typography2:
      "Vedaham Business Solution integrates robust security measures into their solutions, implementing encryption, authentication, and authorization protocols to safeguard client data, ensuring the confidentiality and integrity of sensitive information.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-development-service-new-icon6.png?updatedAt=1712844354973",
    Typography1: "Error / Bug Fixing",
    Typography2:
      "Vedaham Business Solution prioritizes prompt identification and resolution of coding errors and bugs, ensuring their solutions maintain optimal functionality and performance, delivering a seamless experience for end-users while minimizing disruptions.",
  },
];


const Listcontent = [
  {
    listimg: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/10.png?updatedAt=1712844349378",
    listtxt: "Fully Responsive",
  },
  {
    listimg: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/20.png?updatedAt=1712844349540",
    listtxt: "Device Testing",
  },
  {
    listimg:
      " https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/30.png?updatedAt=1712844349564",
    listtxt: "Clean & Modern",
  },
  {
    listimg:
      " https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/40.png?updatedAt=1712844349844",
    listtxt: "Great Experience",
  },
];
