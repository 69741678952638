import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import gi from "../Assets/gal1.png";
import fm from "../Assets/fm.png";
import Toy from "../Assets/toys.png";
import sy from "../Assets/sy.png";
import re from "../Assets/revir.png";
import ImageListItem from "@mui/material/ImageListItem";
import {
  AllCards,
  Buttons,
  CardsSection,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Link } from "react-router-dom";
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const Webdesign = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>


      <Grid container spacing={5} sx={{ pt: "6%", pl: "6%", pr: "6%" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-design-ipad-demo.jpg?updatedAt=1712844350115"
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    Innovative & Creative Design Elements Incorporated for Every
                    Web Page.
                  </>
                }
                fontWeight="bolder"
                color="#1E1666"
                fontSize="30px"
                // variant={!isSmallScreen ? "h5" : "h5"}
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? "0" : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>We provide the best user-interface experience to clients.</>
                }
                fontSize="1.35rem"
                color="#6A7C92"
                fontStyle="italic"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    At Vedaham Business Solutions, we transcend conventional
                    boundaries to infuse innovation and creativity into every
                    aspect of web design. Our commitment to crafting captivating
                    digital experiences is evident in our meticulous attention
                    to detail and our dedication to pushing the boundaries of
                    creativity. Here's how we incorporate innovative and
                    creative design elements into every web page:
                  </>
                }
                fontSize="1rem"
                color="#6A7C92"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
              <br />
            </Grid>
            {Listcontent2.map((data, index) => (
              <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{ backgroundColor: "transparent" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <ListItemIcon>
                      <ArrowRightAltIcon sx={{ color: "red" }} />
                    </ListItemIcon>
                    <TypographyText Typography={data.listtxt} color="#827C92" />
             
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
            
          </Grid>
        </Grid>
      </Grid>
      <Section2
        pt="5%"
        text1="Web Designs that Bring a Smile on Faces"
        text2={
          <>
            At Vedaham Business Solutions, we specialize in crafting web designs
            that evoke joy and leave a lasting impact. Our approach focuses on:
          </>
        }
      />

      <Grid
        container
        spacing={3}
        sx={{
          p: "6%",
          backgroundImage:
            "url(https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-design-service-banner.png?updatedAt=1712844350000)",
          backgroundRepeat: "no-repeat",
        }}
      >
        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <AllCards
              image={data.image}
              height="70px"
              width="70px"
              backgroundColor="#FFFFFF"
              boxShadow="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              // Buttonname={data.Buttonname}
              fontWeight="600"
              color="red"
              txt2textAlign="left"
            />
          </Grid>
        ))}

        <Grid item lg={7} md={7} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>Bespoke Web Design Solutions for New Company Websites</>
                }
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Experience the power of bespoke web design solutions
                    tailored specifically for new company websites at Vedaham
                    Business Solutions. Our team is dedicated to crafting unique
                    and innovative designs that perfectly reflect your brand's
                    identity and values. From sleek and modern layouts to
                    dynamic and interactive experiences, we offer customized
                    solutions to suit your needs. With a focus on user
                    experience and functionality, we ensure that your website
                    not only looks stunning but also performs seamlessly across
                    all devices. Let us help you establish a strong online
                    presence and make a memorable impression in the digital
                    world. Contact us today to get started on your journey to
                    success.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>
            
          </Grid>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/web-design-digital-peoduct-img.png?updatedAt=1712844350128"
            alt=""
            width="100%"
          />
        </Grid>
      </Grid>

      <Section2
        pt="5%"
        text1="Our Stunning Portfolios"
        text2={
          <>
            Vedaham Business Solutions specializes in crafting innovative
            digital solutions tailored to empower businesses across various
            industries. With a history spanning five years, we've delivered over
            10,000 designs, extending our global reach. Our portfolio showcases
            expertise in graphic and presentation design, SEO, digital
            marketing, website and mobile app development, product branding,
            logo design, web design, and social media marketing. Let us
            transform your digital
          </>
        }
      />

      <Grid
        container
        spacing={3}
        sx={{
          p: "6%",
          width: "100%",
          marginLeft: 0,
        }}
      >
        {Imageset.map((data, index) => (
          <Grid
            item
            xs={12}
            lg={index == 0 || index == 4 ? 7 : index == 2 ? 12 : 5}
            md={index == 0 || index == 4 ? 7 : index == 2 ? 12 : 5}
            sm={index === 2 ? 6 : 12}
          >
            <img
              src={data.img}
              alt=""
              width="100%"
              style={{
                maxHeight: "50vh",
                minHeight: "50vh",
                borderRadius: "10px",
              }}
            />
            <TypographyText
              Typography={
              
                <a href={`https://${data.link}`} target="_blank" rel="noopener noreferrer">www.{data.link}</a>
              // <Link to={`https://${data.link}`}>{`www.${data.link}`}</Link>
            }
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Webdesign;
const Listcontent2 = [
  {
    listtxt:
      "Interactive Interfaces: Engage visitors with seamless functionality and captivating design, creating immersive browsing experiences.",
  },
  {
    listtxt:
      "Dynamic Content Presentation: Keep your site fresh and engaging with animated graphics, parallax scrolling, and other dynamic elements",
  },
  {
    listtxt:
      "Custom Illustrations and Graphics: Infuse personality into your brand with custom illustrations and graphics, creating visually striking web pages that leave a lasting impression.",
  },
];

const Data = [
  {
    Buttonname: "Contact Me",
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/flat-design-responsive-website-design_23-2149488184.avif?updatedAt=1712844349900",
    Typography1: "Responsive Design",
    Typography2: (
      <>
        Ensuring seamless user experiences across all devices, our websites
        adapt effortlessly to various screen sizes, guaranteeing accessibility
        and usability for all visitors.
        <br />
        <br />
        <br />
      </>
    ),
  },
  {
    Buttonname: "Contact Me",
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/ui-ux-design-concept-creating-an-application-design-content-and-text-place-illustration-vector.jpg?updatedAt=1712844349840",
    Typography1: "UI/UX Rich Design",
    Typography2: (
      <>
        By prioritizing user interface (UI) and user experience (UX) design
        principles, we create visually appealing and intuitively navigable
        websites that engage users from the moment they land on your page.{" "}
        <br />
        <br />
      </>
    ),
  },
  {
    Buttonname: "Contact Me",
    image: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-webdevlopment/image00.webp?updatedAt=1712844350025",
    Typography1: "Innovative Interaction",
    Typography2:
      "Implementing innovative interactive elements, such as animations, microinteractions, and intuitive navigation, we enhance user engagement and make browsing your website an enjoyable experience for every visitor.",
  },
];
const Imageset = [
  {
    img: sy,
    link: `symphonyperfume.com`,
  },

  {
    img: gi,
    link: `thelvt.co`,
  },
  {
    img: fm,
    link: `myfamilyfitness.com`,
  },
  {
    img: re,
    link: `rivierait.co`,
  },
  {
    img: Toy,
    link: `toycity.me`,
  },
];
