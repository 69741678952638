import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AllCards,
  Buttons,
  CardsSection,
  MetaComponent,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import "../Global.css";
import { Button } from "@mui/material";

const Seo = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const contactform = [
    { placeholder: "Email Address", name: "email", type: "email" },
    { placeholder: "Your Website URL", name: "url", type: "text" },
    { placeholder: "Your Full Name", name: "from_name", type: "text" },
    { placeholder: "Phone Number", name: "phone", type: "text" },
  ];
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_euk05dp",
        "template_0v9kktd",
        form.current,
        "-ER35YKpfAl5T5gD8"
      )
      .then(
        (result) => {
          toast.success("Thank you for contact us", {
            position: "top-right",
            autoClose: 3000, // Duration in milliseconds
          });
        },
        (error) => {
          toast.error("hai", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      {/* <MetaComponent dynamicTitle="" dynamicKeywords="" dynamicDescription="" /> */}
      <MetaComponent dynamicTitle="Top SEO Agency in Dubai | Best SEO Company In UAE" 
      dynamicKeywords="Top SEO Agency in Dubai | Best SEO Company In UAE" 
      dynamicDescription="Looking for an SEO Agency for your business? Vedaham Business Solution provide the best SEO Strategies to keep you on top of Search Engines. One of the prominent SEO Agency in Dubai." />
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="Search Engine Optimization"
        txt2="Welcome to Vedaham Business Solutions, where we specialize in elevating your online visibility through expert Search Engine Optimization services​"
        imges='https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/search-engine.f4674cc861bee37edb52.png?updatedAt=1712841980885'     />
      {/****************************************section 1****************************************************** */}

      <Section2
        pt="5%"
        text1="SEO Services Perfectly Customized to Industry Standards"
        text2={
          <>
            Welcome to Vedaham Business Solutions, where we specialize in
            providing customized SEO services designed to elevate visibility and
            drive growth in today's digital landscape. As a forward-thinking
            agency, we are dedicated to crafting compelling narratives,
            fostering engagement, and implementing cutting-edge strategies that
            transcend industry standards. With our expertise and tailored
            approach, we empower businesses to thrive online, ensuring that
            their brands stand out and succeed.Partner with Vedaham today and
            redefine your digital presence for unparalleled success.
          </>
        }
      />
      {/****************************************section 2****************************************************** */}

      <Grid
        container
        spacing={2}
        sx={{
          p: "5%",
          backgroundImage:
            "url(https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-curve-line.png?updatedAt=1712843043675)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {Datar.map((data, index) => (
          <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
            <div style={{ position: "relative" }}>
              <img
                src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-polygon-banner.png?updatedAt=1712843101408"
                alt="diagonal_image"
                style={{ top: 0, left: 0, zIndex: -1 }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "35%",
                  transform: "translate(-20%, -50%)",
                  zIndex: 1,
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: 'flex-start',
                }}
              >
                <img
                  src={data.img}
                  alt="data_image"
                  width={"60px"}
                  style={{ margin: "auto" }}
                />

                <TypographyText
                  Typography={data.text}
                  fontSize="1.20rem"
                  color="#1E1666"
                  fontWeight="bolder"
                />
              </Box>
            </div>
          </Grid>
        ))}
      </Grid>
      {/****************************************section 3****************************************************** */}

      <Grid container spacing={2}>
        <Grid
          item
          lg={5}
          md={5}
          xs={12}
          sm={12}
          sx={{
            backgroundImage:
              "url(https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-curve-banner.png?updatedAt=1712843209255)",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-growth-img.png?updatedAt=1712841980531"
            alt="diagonal_image"
            width={"100%"}
            style={{ zIndex: 1 }}
          />
        </Grid>

        <Grid item lg={7} md={7} xs={12} sm={12}>
          <Grid
            container
            spacing={2}
            sx={{
              pl: "5%",
              pr: "5%",
              pb: "5%",
              pt: "5%",
            }}
          >
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    Engaging in Fruitful Partnerships for a Successful Outcome
                  </>
                }
                fontWeight="bolder"
                color="#1E1666"
                fontSize="30px"
                // variant={!isSmallScreen ? "h5" : "h5"}
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? "0" : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    At Vedaham Business Solutions, we prioritize fruitful
                    partnerships as the cornerstone of our mission, driving
                    towards successful outcomes through collaboration and
                    commitment to excellence.
                  </>
                }
                fontSize="1.15rem"
                color="#6A7C92"
                fontStyle="italic"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
              <br />
            </Grid>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    Engaging in fruitful partnerships is at the core of our
                    mission at Vedaham Business Solutions, where we believe that
                    collaboration is key to achieving successful outcomes. By
                    fostering strong relationships built on trust,
                    communication, and mutual respect, we ensure that every
                    project we undertake is met with enthusiasm and dedication.
                    Whether working with clients, vendors, or industry
                    collaborators, our commitment to excellence drives us to
                    seek innovative solutions, share expertise, and collectively
                    overcome challenges. Through these collaborative efforts, we
                    strive to achieve nothing short of outstanding results,
                    consistently exceeding expectations and forging enduring
                    partnerships that lay the foundation for continued success.
                  </>
                }
                color="#6A7C92"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />{" "}
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Section2
        pt="2%"
        text1="Core Business Areas"
        text2={
          <>
            In the realm of digital business, optimizing online presence is
            paramount for success. Elevating visibility, attracting the right
            audience, and maintaining a positive reputation are foundational to
            achieving this goal. Within Vedhum Business Solutions, our core
            business areas encompass a strategic focus on various aspects of
            Search Engine Optimization (SEO) and digital marketing. These areas
            not only enhance the visibility of our clients' websites but also
            drive sustainable growth and foster a strong online reputation.
            Let's delve into each core area to understand how they contribute to
            our overarching objective of maximizing online impact and success.
          </>
        }
      />

      {/************************************************************************************************* */}
      <Grid
        container
        spacing={2}
        sx={{
          p: "6%",
        }}
      >
        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={6}>
            <AllCards
              boxShadow="none"
              image={data.image}
              height="50px"
              width="50px"
              backgroundColor="transparent"
              boxShadow1="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              txt2textAlign="left"
            />
          </Grid>
        ))}
      </Grid>

      {/*************************************/}
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: `url(
            https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-traffic-banner.jpg?updatedAt=1712841995167)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover", // Added property to cover the background
          backgroundPosition: "center", // Added property to center the background
          pt: "2%",
          pl: "10%",
          pr: "10%",
          pb: "6%",
        }}
      >
        <Grid item xs={12}>
          <Box sx={{ margin: "auto" }}>
            <Grid container spacing={2} sx={{ margin: "auto" }}>
              <Grid
                item
                lg={7}
                xs={12}
                md={7}
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <Box sx={{}}>
                  <TypographyText
                    Typography={<>Want Free SEO Quotes?​</>}
                    color="white"
                    fontWeight="bolder"
                    fontSize={!isSmallScreen ? "40px" : "30px"}
                  />
                  <br />
                </Box>
                <img
                  src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-traffic-img.png?updatedAt=1712841995335"
                  alt=""
                  width={"100%"}
                  style={{ margin: "auto" }}
                />
              </Grid>
              <Grid item lg={5} xs={12} md={5} sm={12} sx={{ margin: "auto" }}>
                <form action="" ref={form} onSubmit={sendEmail}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: "2%",
                      backgroundColor: "whitesmoke",
                      borderRadius: "10px",
                    }}
                  >
                    {contactform.map((data, index) => (
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sx={{ pr: "16px", pb: "16px" }}
                      >
                        <input
                          fullWidth
                          type={data.type}
                          // name="name"
                          name={data.name}
                          placeholder={data.placeholder}
                          required
                          style={{
                            height: "40px",
                            width: "100%",
                            border: "none",

                            backgroundColor: "#FDFEFF",
                          }}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} sx={{ pr: "16px", pb: "16px" }}>
                      <Button
                        variant="contained"
                        type="submit"
                        className="button1"
                        sx={{
                          bgcolor: "#BD0F65",
                          color: "#fff",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/************************************************ */}
      <Section2
        pt="5%"
        text1="Our Pricing Plans"
        text2={
          <>
            Elevate your online presence with Vedaham business solutions'
            tailored marketing plans, designed to optimize SEO, enhance social
            media engagement, and drive tangible results for businesses of all
            sizes. Choose from our range of Bronze, Silver, Gold, and Platinum
            packages to unlock your business's full potential in the digital
            realm.
          </>
        }
      />
      <Grid
        container
        spacing={2}
        sx={{
          p: "6%",
        }}
      >
        {plan.map((data, index) => (
          <Grid item xs={12} lg={3} md={6} sm={6}>
            <AllCards
              image={data.image}
              height="70px"
              width="70px"
              backgroundColor="#FFFFFF"
              boxShadow=" 9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
            />
          </Grid>
        ))}
      </Grid>
      {/************************************************************* */}
      <Grid container spacing={5}>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Grid container sx={{ p: "6%" }}>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>Advanced Web Marketing Strategies for New Firms</>
                }
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Are you a new firm seeking to dominate the online market? At
                    Vedham Business Solutions, we specialize in advanced web
                    marketing strategies tailored to elevate your brand. Here's
                    how we can help:
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>

            <Grid item xs={12}>
              {Listcontent.map((data, index) => (
                <ListItem disablePadding>
                  <ListItemButton
                    style={{ backgroundColor: "transparent" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <ListItemIcon>
                      <img src={data.listimg} alt="" width="40px" />
                    </ListItemIcon>
                    &nbsp;
                    <ListItemText
                      primary={data.listtxt}
                      sx={{ color: "#6A7C92", fontSize: ".9rem" }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{
            backgroundImage: `url(
              https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-curve-banner2.png?updatedAt=1712843436744)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover", // Added property to cover the background
            backgroundPosition: "center", // Added property to center the background
          }}
        >
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-better-experience-img1.png?updatedAt=1712841981356"
            alt=""
            width={!isSmallScreen ? "100%" : "80%"}
            style={{ margin: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Seo;
const Datar = [
  {
    img: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-icon-new1-1.png?updatedAt=1712841983701",
    text: "Cloud Storage",
  },
  {
    img: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-icon-new1-2.png?updatedAt=1712841983849",
    text: "Traffic Growth",
  },
  {
    img: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-icon-new1-3.png?updatedAt=1712841985067",
    text: "Keyword Ranking",
  },
  {
    img: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-icon-new1-4.png?updatedAt=1712841985910",
    text: "Performance Metrics",
  },
];

const Data = [
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-service-new-icon1.png?updatedAt=1712841985783",
    Typography1: "Informative Content",
    Typography2:
      "Creating high-quality, relevant content that provides valuable information to users. This can include articles, blog posts, infographics, videos, etc. Content should be optimized with keywords to attract organic traffic and engage visitors.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-service-new-icon2.png?updatedAt=1712841986230",
    Typography1: "Visitor Growth",
    Typography2:
      "Implementing strategies to attract more visitors to the website. This involves optimizing the website for search engines, promoting content through various channels (social media, email marketing, etc.), and enhancing user experience to encourage repeat visits.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-service-new-icon3.png?updatedAt=1712841987039",
    Typography1: "Google Analytics",
    Typography2:
      "Utilizing Google Analytics to track website traffic, user behavior, and other important metrics. Analyzing this data helps in understanding the effectiveness of SEO efforts, identifying areas for improvement, and making informed decisions to optimize the website further.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-service-new-icon4.png?updatedAt=1712841987117",
    Typography1: "Innovative Strategies",
    Typography2:
      "Developing creative and unique approaches to SEO to stay ahead of competitors. This can include experimenting with new techniques, leveraging emerging trends, and adapting strategies based on changes in search engine algorithms.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-service-new-icon5.png?updatedAt=1712841994979",
    Typography1: "Link Building",
    Typography2:
      "Acquiring high-quality backlinks from reputable websites to improve the website's authority and search engine ranking. This involves outreach, guest posting, content partnerships, and creating shareable content that naturally attracts links.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/seo-service-new-icon6.png?updatedAt=1712841995095",
    Typography1: "Reputation Management",
    Typography2:
      "Managing online reputation by monitoring and responding to reviews, mentions, and comments across various platforms. Building a positive online reputation enhances credibility and trustworthiness, which can indirectly impact search engine rankings.",
  },
];

const plan = [
  {
    image: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/01-bronze-plan.png?updatedAt=1712841979598",
    Typography1: "Bronze",
    Typography2: (
      <>
        6 Months Contract Periods
        <br /> <br />
        <hr className="line_color" />
        50 Keyword, Keyword Research & Analysis
        <hr className="line_color" />
        Monthly Reporting
        <hr className="line_color" />
        Website Audit ,Backlink AUditing
        <hr className="line_color" />
        Off-Page SEO/On-Page SEO
        <hr className="line_color" />
        Website Blogs 1/Months
        <hr className="line_color" />
        Social Media Optimization
        <hr className="line_color" />
        Inforgraphics Creation & Submission 10
        <br /> <br />
      </>
    ),
    Buttonname: "Select Plan",
  },
  {
    image: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/Silver-Icon.png?updatedAt=1712841995444",
    Typography1: "Sliver",
    Typography2: (
      <>
        6 Months Contract Periods
        <br /> <br />
        <hr className="line_color" />
        100 Keyword, Keyword Research & Analysis
        <hr className="line_color" />
        Monthly Reporting
        <hr className="line_color" />
        Website Audit ,Backlink AUditing
        <hr className="line_color" />
        Off-Page SEO/On-Page SEO
        <hr className="line_color" />
        Website Blogs 2/Months
        <hr className="line_color" />
        Social Media Optimization
        <hr className="line_color" />
        Inforgraphics Creation & Submission 15
        <br /> <br />
      </>
    ),
    Buttonname: "Select Plan",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/images.jpeg?updatedAt=1712841980352",
    Typography1: "Gold",
    Typography2: (
      <>
        6 Months Contract Periods
        <br /> <br />
        <hr className="line_color" />
        150 Keyword, Keyword Research & Analysis
        <hr className="line_color" />
        Bi-Weekly Reporting
        <hr className="line_color" />
        Website Audit ,Backlink AUditing
        <hr className="line_color" />
        Off-Page SEO/On-Page SEO
        <hr className="line_color" />
        Website Blogs 3/Months
        <hr className="line_color" />
        Social Media Optimization
        <hr className="line_color" />
        Inforgraphics Creation & Submission 25
        <br /> <br />
      </>
    ),
    Buttonname: "Select Plan",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/platinum-icon-22.jpg?updatedAt=1712841981967",
    Typography1: "Platimum",
    Typography2: (
      <>
        6 Months Contract Periods
        <br /> <br />
        <hr className="line_color" />
        200 Keyword, Keyword Research & Analysis
        <hr className="line_color" />
        Bi-Weekly Reporting
        <hr className="line_color" />
        Website Audit ,Backlink AUditing
        <hr className="line_color" />
        Off-Page SEO/On-Page SEO
        <hr className="line_color" />
        Website Blogs 5/Months
        <hr className="line_color" />
        Social Media Optimization
        <hr className="line_color" />
        Inforgraphics Creation & Submission 30
        <br /> <br />
      </>
    ),
    Buttonname: "Select Plan",
  },
];
const Listcontent = [
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/3494271.png?updatedAt=1712841980406",
    listtxt: "We optimize your website to rank higher in search engine results, driving organic traffic and boosting visibility.",
  },
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/3079941.png?updatedAt=1712841979974",
    listtxt: "Our experts craft engaging content that resonates with your audience, positioning your brand as an industry authority and driving valuable traffic.",
  },
  {
    listimg:
      " https://ik.imagekit.io/41cnxfw1v/vbsimages/services-seo/1968641.png?updatedAt=1712841980324",
    listtxt: "Leveraging social platforms, we enhance brand awareness and engagement, fostering meaningful connections with your audience.",
  },
];
