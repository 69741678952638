import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AllCards,
  Buttons,
  CardsSection,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import {
  Box,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Vertsteps from "./Vertsteps";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Aboutus = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);

  return (
    <>
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="About Company"
        txt2="
        At Vedaham Business Solutions, we specialize in crafting captivating graphic designs that elevate brand identities with meticulous attention to detail."
        imges="https://ik.imagekit.io/41cnxfw1v/vbsimages/aboutus/about.png?updatedAt=1712849076220"
      />
      <Grid container spacing={5} sx={{ pt: "6%", pl: "6%", pr: "6%" }}>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/aboutus/about-dynamic-team-im.jpg?updatedAt=1712849076089"
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Crafting Excellence for 5 Years.</>}
                fontWeight="bolder"
                color="#1E1666"
                fontSize="30px"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? "0" : "auto" }}
              ></hr>
            </Grid>

            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                   At Vedaham Business Solutions, we transcend the traditional role of a digital agency. We see ourselves as partners in your progress, driven by a singular passion to empower businesses with cutting-edge digital solutions that redefine success.
                  </>
                }
                color="#6A7C92"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
              <br />
            </Grid>
            {Listcontent2.map((data, index) => (
              <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{ backgroundColor: "transparent" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <ListItemIcon>
                      <ArrowRightAltIcon sx={{ color: "red" }} />
                    </ListItemIcon>
                    <TypographyText Typography={data.listtxt} color="#827C92" />
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
            
          </Grid>
        </Grid>
      </Grid>
      {/*********************section 3*********************/}
      <Grid
        container
        spacing={3}
        sx={{
          p: "6%",
        }}
      >
        {Listcontent.map((data, index) => (
          <Grid item xs={12} lg={4} md={12} sm={12} key={index}>
            <TypographyText
              Typography={<>A Bunch of Enthusiastic & Creative Minds.</>}
              fontWeight="bolder"
              color="#1E1666"
              fontSize="30px"
              textAlign={!isSmallScreen ? "left" : "centre"}
            />
            <br />
            <hr
              width="50px;"
              color="red"
              size="5"
              style={{ margin: !isSmallScreen ? "0" : "auto" }}
            ></hr>
            <br />
            {Content.map((data, index) => (
              <div key={index}>
                <TypographyText
                  Typography={data.txt1}
                  fontWeight="bolder"
                  color="#1E1666"
                  fontSize="22px"
                  textAlign={!isSmallScreen ? "left" : "centre"}
                />
                <TypographyText
                  Typography={data.txt2}
                  color="#6a7c92"
                  textAlign={!isSmallScreen ? "left" : "centre"}
                />
              </div>
            ))}
          </Grid>
        ))}

        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={6} sm={12}>
            <AllCards
              cardheight="400px"
              image={data.image}
              height="200px"
              width="200px"
              backgroundColor="#FFFFFF"
              boxShadow="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
            />
          </Grid>
        ))}
      </Grid>
      <Section2

        text1="Milstone"
        text2={
          <>
            {" "}
            Embarking on an inspiring journey, we've transformed into a global force in digital innovation, catering to diverse client needs worldwide.
          </>
        }
      />
      <Vertsteps />

      <Grid container spacing={5} sx={{ pt: "6%", pl: "6%", pr: "6%" ,pb:'6%'}}>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/aboutus/about-faq-img.jpg?updatedAt=1712849076117"
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Commonly Asked Questions.</>}
                fontWeight="bolder"
                color="#1E1666"
                fontSize="30px"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? "0" : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    Welcome to Vedaham Business Solutions' Frequently Asked Questions (FAQ) section. Here, we address common queries about our services, values, and processes to provide you with clarity and insight into how we can assist you in achieving your digital goals. Explore our responses to discover how Vedaham stands out in the digital landscape and how our innovative solutions can drive growth and success for your business
                  </>
                }
                color="#6A7C92"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>

            <Grid item xs={12}>
              {Accordn.map((data, index) => (
                <>
                  <Accordion defaultExpanded={index === 0 ? true : false} sx={{backgroundColor:'#F6FAFC',marginBottom:'10px'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <TypographyText
                        Typography={data.text1}
                        fontWeight="bolder"
                        color="#1E1666"
                        fontSize="18px"
                        textAlign='left'
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <TypographyText
                        Typography={data.text2}
                        color="#6A7C92"
                        textAlign={!isSmallScreen ? "left" : "centre"}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Aboutus;
const Accordn = [
  {
    text1: "What sets Vedaham Business Solutions apart from other digital agencies?",
    text2:
      "At Vedaham, we differentiate ourselves through our unwavering commitment to client success. We thrive on challenges, driven by innovation, and dedicated to delivering results. Our approach focuses on empowering businesses with innovative solutions that drive growth, inspire change, and foster enduring relationships.",
  },
  {
    text1: "How can Vedaham help my business succeed in the digital landscape?",
    text2:
      "We offer a comprehensive range of services including graphic designing, presentation design, SEO, digital marketing, website development, and mobile app development. Our expertise lies in crafting compelling narratives, driving meaningful engagement, and engineering growth in the ever-evolving digital landscape. By partnering with Vedaham, you gain access to cutting-edge digital solutions tailored to your specific needs, empowering you to transcend boundaries and redefine success in the digital realm.",
  },
  {
    text1: "What is the process for availing Vedaham's presentation design services?",
    text2:
      "To get started with our presentation design services, simply provide us with your slides and let us know what specific improvements or changes you would like to see. Additionally, if you have any branding or style guidelines, we can tailor the makeover to your needs. Our team of skilled designers will then craft visually compelling presentations that effectively convey your message and engage your audience.",
  },
];
const Listcontent2 = [
  {
    listtxt: "Crafting Compelling Narratives: We pride ourselves on our ability to create narratives that resonate with your audience, driving meaningful engagement and fostering lasting connections.",
  },
  {
    listtxt: "Driving Meaningful Engagement: We are committed to helping businesses engage with their audience in meaningful ways, leveraging innovative digital strategies to create impactful interactions.",
  },
  {
    listtxt: "Engineering Growth in the Digital Landscape: As a forward-thinking and dynamic agency, we are dedicated to engineering growth for businesses in the ever-evolving digital landscape, ensuring they stay ahead of the curve and achieve their goals.",
  },
];
const Data = [
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/aboutus/about-experience-img1.jpg?updatedAt=1712849076120",
    Typography1: "Mission",
    Typography2:
      "At Vedaham Business Solutions, our mission is to lead the dynamic realm of technology and marketing by empowering businesses with innovative solutions that drive growth, inspire change, and foster enduring relationships. With a singular passion for transforming visions into digital reality, we aspire to be the catalyst for our clients' digital success. Guided by our unwavering commitment to excellence and fueled by creativity and innovation, we strive to craft compelling narratives, engineer growth, and drive meaningful engagement in the ever-evolving digital landscape.",
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/aboutus/about-experience-img2.jpg?updatedAt=1712849075897",
    Typography1: "Vision",
    Typography2:
      <>Our vision at Vedaham Business Solutions is to be recognized not only for our technical prowess but also for our dedication to client success. We aim to become trusted partners in progress as we guide businesses on their journey to excellence in the digital sphere. With a relentless pursuit of innovation and a deep understanding of technology and marketing dynamics, we envision ourselves at the forefront of driving change and shaping the future of digital solutions.<br />
     <br/> <br/>  <br/></>
  },
];

const Listcontent = [
  {
    listimg: "https://ik.imagekit.io/41cnxfw1v/vbsimages/aboutus/software-developers-coding-composition-with-creative-decisions-algorithmic-complexity-documentation-by-programming-languages-isometric_1284-33290.avif?updatedAt=1712849076299",
    listtxt: "Fully Responsive",
  },
];
const Content = [
  {
    txt1: <>Passionate Professionals<br/><br/></>,
    txt2: <>Our team is comprised of passionate professionals who are driven by a shared dedication to excellence. We approach each project with enthusiasm and commitment, ensuring that every aspect is meticulously crafted to meet our clients' needs.<br/><br/></>,
  },
  {
    txt1: <>Innovative Thinkers<br/><br/></>,
    txt2: "With a culture of innovation at our core, we thrive on pushing boundaries and exploring new ideas. Our creative minds are constantly seeking fresh perspectives and inventive solutions to challenges, ensuring that we deliver forward-thinking results that stand out in the digital landscape.",
  },
];
