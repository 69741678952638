import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import "aos/dist/aos.css";
import AOS from "aos";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import whitelogo from "../Assets/VBS White Logo.png";
import bluelogo from "../Assets/VBS logo-01_Org.png";
import ResponsiveHeader, { dropdown } from "./ResponsiveHeader";

import MenuItem from "@mui/material/MenuItem";

import Menu from "@mui/material/Menu";
export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  // console.log(matches, "matches");
  function refreshPage() {
    window.location.href = "/";
  }
  React.useEffect(() => {
    AOS.init({
      // Global settings here
      duration: 1000,
      once: true, // Only animate once
    });
  }, []);
  const location = useLocation();
  const [headerBackground, setHeaderBackground] = React.useState("transparent");
  const [headercolor, setHeadercolor] = React.useState(
    location.pathname === "/" ? "#1E1666 " : "white"
  );
  const [images, setimage] = React.useState(
    location.pathname === "/" ? bluelogo : whitelogo
  );
  // console.log(location.pathname, "location");
  React.useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // console.log(window.scrollY, "scrolly");
      if (window.scrollY > 0) {
        setHeaderBackground("white");
        setHeadercolor("#1E1666");
        setimage(bluelogo);
      } else {
        setHeaderBackground("transparent !important");
        setHeadercolor(
          window.location.pathname === "/" ? "#1E1666 " : "white "
        );
        setimage(window.location.pathname === "/" ? bluelogo : whitelogo);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    setHeadercolor(location.pathname === "/" ? "#1E1666 " : "white");
    setimage(location.pathname === "/" ? bluelogo : whitelogo);
  }, [location.pathname]);
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          // marginBottom:'80px'
        }}
      >
        <AppBar
          sx={{
            backgroundColor: headerBackground,
            width: "100%",
            // maxHeight:'80px',

            justifyContent: "space-between",
            position: "sticky !impoertant",
            top: "0px",
            boxShadow: "none",
          }}
          position="fixed"
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {matches ? (
              <>
                <div data-aos="fade-up" data-aos-duration="1000">
                  <img
                    src={images}
                    width={150}
                    alt="logo"
                    height={"auto"}
                    onClick={refreshPage}
                    style={{ marginTop: "10px" }}
                  />
                </div>
                <Box>
                  <Button
                    sx={{
                      color:
                        location.pathname === "/" ? "#1E1666" : headercolor,
                      fontWeight: "500",

                      fontFamily: "Inter",
                      fontSize: "1rem",
                      textTransform: "capitalize",

                      minWidth: "120px",
                    }}
                    className="buttton_eader"
                    disableRipple
                    onClick={() => {
                      handleClose(); // Close the menu when a navigation item is clicked
                      navigate("/"); // Navigate to the specified route
                    }}
                  >
                    Home
                    {/* <Link to="/">Home</Link> */}
                  </Button>

                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      color:
                        location.pathname === "/" ? "#1E1666" : headercolor,
                      fontWeight: "500",

                      fontFamily: "Inter",
                      fontSize: "1rem",
                      textTransform: "capitalize",

                      minWidth: "120px",
                    }}
                  >
                    Services &nbsp;&nbsp; <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {dropdown.map((data, index) => (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate(data.link);
                        }}
                        key={index}
                        style={{ color: "#1E1666" }}
                      >
                        {" "}
                        {data.name}
                      </MenuItem>
                    ))}
                  </Menu>

                  {navItems.map((item, index) => (
                    <Button
                      disableRipple
                      key={index}
                      sx={{
                        // color: 'red',
                        color: headercolor,
                        fontWeight: "500",

                        fontFamily: "Inter",
                        fontSize: "1rem",
                        textTransform: "capitalize",

                        minWidth: "120px",
                      }}
                      className="buttton_eader"
                      onClick={() => {
                        handleClose(); // Close the menu when a navigation item is clicked
                        navigate(item.link); // Navigate to the specified route
                      }}
                    >
                      {/* {item} */}
                      {item.name}
                    </Button>
                  ))}
                </Box>
              </>
            ) : (
              <>
                <img
                  src={images}
                    width={150}
                    alt="logo"
                  height={"auto"}
                  onClick={refreshPage}
                />
                <ResponsiveHeader />
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

export const navItems = [
  // <Link to={"/aboutus"}>About Us</Link>,
  // <Link to={"/freequote"}>Free Quote</Link>,
  // <Link to={"/Faq"}>FAQ</Link>,
  // <Link to={"/contactus"}>Contact Us</Link>,
  { link: "/aboutus", name: `About Us` },
  { link: "/freequote", name: `Free Quote` },
  { link: "/Faq", name: `FAQ` },
  { link: "/contactus", name: `Contact Us` },
];

// export const dropdown = [
//   <Link to={"/seo"}>SEO</Link>,
//   <Link to={"/web-development"}>Web Development</Link>,
//   <Link to={"/Presentation-design"}>Presentation Design</Link>,
//   <Link to={"/digital-marketing"}>Digital Marketing</Link>,
//   <Link to={"/graphics-design"}>Graphics Design</Link>,
//   <Link to={"/mobile-app-development"}>Mobile App Development</Link>,
// ];
