import React, { useEffect, useRef } from "react";
import {
  AllCards,
  Buttons,
  CardsSection,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Box, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Faq = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const contactform = [
    { placeholder: "Email Address", name: "email", type: "email" },
    { placeholder: "Your Website URL", name: "url", type: "text" },
    { placeholder: "Your Full Name", name: "from_name", type: "text" },
    { placeholder: "Phone Number", name: "phone", type: "text" },
  ];
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_euk05dp",
        "template_0v9kktd",
        form.current,
        "-ER35YKpfAl5T5gD8"
      )
      .then(
        (result) => {
          toast.success("Thank you for contact us", {
            position: "top-right",
            autoClose: 3000, // Duration in milliseconds
          });
        },
        (error) => {
          toast.error("hai", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="Commonly Asked Questions"
        txt2="Elevate your online presence with Vedaham business solutions, where innovation meets digital success​"
        imges="https://ik.imagekit.io/41cnxfw1v/vbsimages/faqcontactus/faq.png?updatedAt=1712849110491"
      />

      <Grid
        container
        spacing={5}
        sx={{
          p: "6%",
        }}
      >
        <Grid item lg={7} md={7} xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Most Frequent Questions</>}
                fontWeight="bolder"
                color="#1E1666"
                fontSize="30px"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? "0" : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <TypographyText
                Typography={
                  <>
                    Welcome to Vedaham business solutions Most Frequent Questions
                    section, your comprehensive guide to understanding our SEO
                    services. Here, we address common inquiries to provide
                    clarity and insight into how we elevate your online
                    presence. Explore answers tailored to your concerns and
                    embark on a journey towards digital success with us.
                  </>
                }
                color="#6A7C92"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>

            <Grid item xs={12}>
              {Accordn.map((data, index) => (
                <>
                  <Accordion
                    defaultExpanded={index === 0 ? true : false}
                    sx={{ boxShadow: "none" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <TypographyText
                        Typography={data.text1}
                        fontWeight="bolder"
                        color="#1E1666"
                        fontSize="18px"
                        textAlign='left'
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <TypographyText
                        Typography={data.text2}
                        color="#6A7C92"
                        fontSize="15px"
                        textAlign={!isSmallScreen ? "left" : "centre"}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} md={5} xs={12} sm={12}>

















        <form action="" ref={form} onSubmit={sendEmail}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "2%",
              backgroundColor: "whitesmoke",
              borderRadius: "10px",
            }}
          >     <Grid
          item
          xs={12}>        <TypographyText
          Typography={<>Avail FREE Quotes</>}
          fontWeight="bolder"
          color="#1E1666"
          fontSize="25px"
        /></Grid>
          
            {contactform.map((data, index) => (
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sx={{ pr: "16px", pb: "16px" }}
              >
                <input
                  fullWidth
                  type={data.type}
                  // name="name"
                  name={data.name}
                  placeholder={data.placeholder}
                  required
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "none",

                    backgroundColor: "#FDFEFF",
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} sx={{ pr: "16px", pb: "16px" }}>
              <Button
                variant="contained"
                type="submit"
                className="button1"
                sx={{
                  bgcolor: "#BD0F65",
                  color: "#fff",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                Send Message
              </Button>
       
            </Grid>
          </Grid>
        </form>
   
        </Grid>
      </Grid>

      {/*********************************8 */}
      <Grid
        container
        Spacing={2}
        sx={{
          pl: "6%",
          pr: "6%",
          pb: "6%",
          backgroundImage:
            "url(https://ik.imagekit.io/41cnxfw1v/vbsimages/faqcontactus/faq-tilt-banner.jpg?updatedAt=1712849885390)",
          backgroundRepeat: "none",
        }}
      >
        <Grid item xs={12}>
          <Section2
            pt="5%"
            text1="More Help Needed?"
            text2={
              <>
               Unlock the full potential of your online presence with Vedaham business solutions. Our tailored strategies are designed to optimize your digital impact and drive growth for your business. Take the next step towards increased visibility and success today.
              </>
            }
          />
          <br />
          <br/>
        </Grid>

        {Data.map((data, index) => (
          <Grid item xs={12} lg={4} md={4} sm={6}>
            <AllCards
              boxShadow="none"
        
              height="120px"
              width="120px"
              backgroundColor="transparent"
              boxShadow1="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              txt2textAlign="left"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Faq;
const Data = [
  {

    Typography1: "Optimize Your Online Presence",
    Typography2:
      "Unlock the full potential of your online presence with our tailored solutions.",
  },
  {
    
    Typography1: "Maximize Your Digital Impact",
    Typography2:
      "Let us amplify your digital impact and drive growth for your business.",
  },
  {

    Typography1: "Enhance Your Visibility Today",
    Typography2:
      "Take the next step towards increased visibility and success with our expertise.",
  },
];
const contactform = [
  { placeholder: "Email Address" },
  { placeholder: "Your Website URL" },
  { placeholder: "Your Full Name" },
  { placeholder: "Phone Number" },
];
const Accordn = [
  {
    text1: "How can Vedaham business solutions help improve my website's search engine rankings?",
    text2:
      "By implementing proven SEO techniques tailored to your website's needs, we elevate your search engine rankings effectively.",
  },
  {
    text1: "What specific SEO strategies does Vedaham business solutions employ to drive organic traffic?",
    text2:
      "Vedaham business  Solutions employs a multifaceted approach including keyword optimization, content enhancement, and backlink building to drive organic traffic.",
  },
  {
    text1: "Can Vedaham business solutions Solutions assist with keyword research and optimization for my target audience?",
    text2:
      "Yes,Vedaham business solutions conducts thorough keyword research and implements optimization strategies to resonate with your target audience .",
  },
  {
    text1: "How does Vedaham business solutions measure the success of their SEO campaigns?",
    text2:
      "Vedaham business solutions gauges success through metrics like increased website traffic, higher search engine rankings, and improved conversion rates.",
  },
  {
    text1: "What sets Vedaham business solutions apart from other SEO service providers in the market?",
    text2:
      "Our personalized approach, in-depth industry knowledge, and commitment to results distinguish Vedaham business solutions in the SEO landscape.",
  },
];
