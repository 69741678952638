import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Buttons,
  CardsSection,
  MetaComponent,
  TypographyText,
} from "../ReusableComponent/Reusab";
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const contactform = [
    { placeholder: "Name", name: "from_name", type: "text" },
    { placeholder: "Email Address", name: "email", type: "email" },

    { placeholder: "Message", name: "message", type: "text" },
    { placeholder: "Phone Number", name: "phone", type: "text" },
  ];
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_euk05dp",
        "template_ctedpxi",
        form.current,
        "-ER35YKpfAl5T5gD8"
      )
      .then(
        (result) => {
          toast.success("Thank you for contact us", {
            position: "top-right",
            autoClose: 3000, // Duration in milliseconds
          });
        },
        (error) => {
          toast.error("hai", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          console.log(error.text);
        }
      );
       // Clear the form fields after submission
       form.current.reset();
  };
  return (
    <div>
          <MetaComponent dynamicTitle="Contact Us | Rebranding & Top Digital Agency in Dubai, UAE"
           dynamicKeywords="Contact Us | Rebranding & Top Digital Agency in Dubai, UAE" 
           dynamicDescription="Reach out to us whether its for Rebranding, Marketing, Mobile App Development or Web Design. Vedaham Business Solution provides all kind of Digital Services on time." /> 
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="Contact Us"
        txt2="Connect with Vedaham Business Solutions to turn your digital aspirations into reality.​"
        imges="https://ik.imagekit.io/41cnxfw1v/vbsimages/faqcontactus/contact.png?updatedAt=1712849110270"
      />
      <Grid
        container
        spacing={5}
        sx={{
          p: "6%",
        }}
      >
        <Grid item lg={6} md={6} xs={12} sm={12}>
          <Grid container sx={{}}>
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Get in Touch</>}
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Ready to take your business to the next level? Get in touch
                    with us at Vedaham business solutions, where we're dedicated
                    to turning your goals into reality. Let's collaborate to
                    elevate your online presence and drive sustainable growth.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>

            <Grid item xs={12}>
              {Listcontent.map((data, index) => (
                <ListItem disablePadding>
                  <ListItemButton
                    style={{ backgroundColor: "transparent" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor = "transparent")
                    }
                  >
                    <ListItemIcon>{data.listimg}</ListItemIcon>
                    <ListItemText
                      primary={data.listtxt}
                      sx={{ color: "#6A7C92", fontSize: ".9rem" }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} xs={12} sm={12}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: "3%",
              backgroundColor: "whitesmoke",
              borderRadius: "10px",
            }}
          >
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <TypographyText
                Typography={<>Drop Us a Line</>}
                fontWeight="bolder"
                color="#1E1666"
                fontSize="25px"
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <form action="" ref={form} onSubmit={sendEmail}>
                <Grid container>
                  {contactform.map((data, index) => (
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      sx={{ pr: "16px", pb: "16px" }}
                    >
                      {index === 2 ? (
                        <textarea
                          fullWidth
                          type={data.type}
                          name={data.name}
                          placeholder={data.placeholder}
                          required
                          style={{
                            height: "150px",
                            width: "100%",
                            border: "none",
                            fontFamily: "Inter",
                            backgroundColor: "#FDFEFF",
                          }}
                        />
                      ) : (
                        <input
                          fullWidth
                          type={data.type}
                          name={data.name}
                          placeholder={data.placeholder}
                          required
                          style={{
                            height: "40px",
                            width: "100%",
                            border: "none",
                            fontFamily: "Inter",
                            backgroundColor: "#FDFEFF",
                          }}
                        />
                      )}
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ pr: "16px", pb: "16px" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      className="button1"
                      sx={{
                        bgcolor: "#BD0F65",
                        color: "#fff",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
     
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyADOQouhmntJTwG6N0oAaakDOvLxJuEpKw",
})(Contact);

const Listcontent = [
  {
    listimg: <LocationOnIcon />,
    listtxt: (
      <>
        India : Office #9, Park Center, Technopark, Trivandrum, Kerala
        <br />
        UAE : Office 215, B62, Riggat Al Buteen, Deira, Dubai
      </>
    ),
  },
  {
    listimg: <EmailIcon />,
    listtxt: <>info@vedahamgroup.com</>,
  },
  {
    listimg: <LocalPhoneIcon />,

    listtxt: <>India : +91 892189 0865 / UAE : +971 58 567 2042</>,
  },
  {
    listimg: <LanguageIcon />,

    listtxt: <>www.vedaham.xyz</>,
  },
];
