import React, { useEffect } from "react";
import {
  AllCards,
  Buttons,
  CardsSection,
  LeftRigt,
  MetaComponent,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const Marketinganalysis = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen1 = useMediaQuery(theme.breakpoints.down("sm"));
  const Data = [
    {
      img: "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/marketing-conduct-how-to-conduct-img.jpg?updatedAt=1712846859758",
      txt1: "Digital Marketing Strategies",
      txt2: "There are many variations of lorem passages of Lorem Ipsum available, but the majority have suffered. This book is a treatise on the theory of ethics, very popular during the Renaissance.",
      listContent: [
        {
          listimg:
            "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/marketing-how-to-conduct-icon.jpg?updatedAt=1712846859520",
          listtxt: "1. Online Presence Amplification:",
          listtxt1:
            "Digital marketing strategies focus on enhancing brand visibility, engagement, and reputation across various online platforms, including search engines, social media, websites, and email.",
        },
        {
          listimg:
            "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/marketing-how-to-conduct-icon2.jpg?updatedAt=1712846859609",
          listtxt: "2. Conversion Optimization:",
          listtxt1:
            "Digital marketing tactics aim to drive desired actions from target audiences, such as purchases, sign-ups, or inquiries, through targeted campaigns, persuasive content, and optimized user experiences.",
        },
        // Add more items as needed
      ],
    },
  ];

  return (
    <>
       <MetaComponent 
       dynamicTitle="Digital Marketing Firm in UAE | Online Marketing Agency Dubai" 
       dynamicKeywords="Digital Marketing Firm in UAE | Online Marketing Agency Dubai" 
       dynamicDescription="Get a Quote to promote your brand digitally with Vedaham Business Solutions UAE. One of the best Digital Marketing agency in Dubai & helps to create a unique identity for your brand in the Digital World." /> 
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="Digital Marketing"
        txt2="
        
Digital marketing promotes brands, engages audiences, and drives conversions, serving as a cornerstone for businesses in the digital landscape."
        imges="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/marketing-analysis.png?updatedAt=1712846859453"
      />
      {Data &&
        Data.map((data, index) => (
          <LeftRigt
            key={index}
            img={data.img}
            txt1={data.txt1}
            txt2={data.txt2}
            Listcontent4={data.listContent}
          />
        ))}
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{ position: "relative" }}>
            <Box>
              <img
                src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/marketing-how-to-conduct-banner2.jpg?updatedAt=1712846859403)"
                alt=""
                width="100%"
                style={{
                  zIndex: 1, // Ensure the image is behind the boxes
                }}
              />
            </Box>
            {/* Second Box */}
            <Box>
              <img
                src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/marketing-analysis-business-analysis-banner.jpg?updatedAt=1712846859334"
                alt="img"
                width="100%"
                style={{
                  zIndex: 1,
                  minHeight: isSmallScreen ? "500px" : "600px", // Adjust the height as needed
                }}
              />
            </Box>
            {/* Image */}
            <img
              src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/optimization-and-developing-business-growth-with-advertising-and-promotion-internet-marketing-strategy-planning-and-analysis-flat-human-illustration-for-landing-page-website-mobile-p.jpg?updatedAt=1712846859922"
              alt="img"
              width="70%"
              height="60%"
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)", // Center the image horizontally
                zIndex: 0, // Ensure the image is behind the boxes
              }}
            />

            <Box
              sx={{
                top: isSmallScreen1 ? "50%" : isSmallScreen ? "60%" : "74%",
                left: "8%",
                transform: "translate(-3%, 0%)",
                position: "absolute",
                zIndex: 2, // Ensure the text is above the image
                textAlign: "center",
              }}
            >
              <TypographyText
                Typography={
                  "Maximizing Online Impact and Converting Audiences: The Essence of Digital Marketing."
                }
                color="white"
                fontSize={!isSmallScreen ? "30px" : "20px"}
                fontWeight="600"
              />
              <br />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5} sx={{ p: "6%" }}>
        <Grid item xs={12} lg={6} md={6} sm={6}>
          <TypographyText
            Typography="
                  Digital Marketing Services Offered
                "
            color="#1E1666"
            fontSize="30px"
            fontWeight="600"
            textAlign={!isSmallScreen ? "right" : "centre"}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6} sm={6}>
          <TypographyText
            Typography={
              <>
                While the specifics of Vedaham's digital marketing services are
                not explicitly outlined in the provided text, typical digital
                marketing services may include:
              </>
            }
            color="#6A7C92"
            textAlign="left"
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          pl: "6%",
          pt: "2%",
          pr: "6%",
        }}
      >
        {Datas.map((data, index) => (
          <Grid item xs={12} lg={3} md={6} sm={6}>
            <AllCards
              boxShadow="none"
              image={data.image}
              height="200px"
              width="200px"
              backgroundColor="transparent"
              boxShadow1="9px 17px 30px 0px rgba(0,0,0,.1)"
              backgroundColor1="#FFFFFF"
              txt1color="#1E1666"
              txt2color="#6a7c92"
              txt1fontSize="22px"
              txt2fontSize="15px"
              Typography1={data.Typography1}
              Typography2={data.Typography2}
              txt1fontWeight="bolder"
              //  txt2textAlign="left"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Marketinganalysis;
const Datas = [
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/landing-page-of-seo-search-engine-optimization-modern-flat-design-isometric-template-conceptual-seo-analysis-and-optimization-seo-strategies-and-marketing-concept-illustration-for-we.jpg?updatedAt=1712846859280",
    Typography1: "SEO & Visibility Optimization",
    Typography2: (
      <>
        Enhance website visibility and organic search rankings to attract more
        qualified traffic from search engines.
        <br />
        <br />
        <br />
      </>
    ),
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/Social-Media-Engagement.png?updatedAt=1712846862970",
    Typography1: "Social Media Engagement",
    Typography2: (
      <>
        Leverage social media platforms to build brand awareness, engage
        audiences, and drive traffic through targeted campaigns.
        <br />
        <br />
      </>
    ),
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/accurate-marketing-strategy-content-creation-distribution-target-audience-identification-brand-promotion-smm-expert-analyses-user-behaviour-stats-vector-isolated-concept-metaphor-il.avif?updatedAt=1712846859268",
    Typography1: "Targeted Advertising",
    Typography2: (
      <>
        Run precise and cost-effective ad campaigns on search engines or social
        media platforms to reach specific audiences and drive conversions.
      </>
    ),
  },
  {
    image:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-digitalmarketing/data-analyst-oversees-governs-income-expenses-with-magnifier-financial-management-system-finance-software-it-management-tool-concept_335657-1891.avif?updatedAt=1712846859487",
    Typography1: "Data-driven Optimization",
    Typography2: (
      <>
        Monitor campaign performance, analyze key metrics, and optimize
        marketing strategies based on insights to maximize ROI and achieve
        business goals.
      </>
    ),
  },
];
