import React from "react";
import { useState, useEffect } from "react";
import { Button, Drawer } from "@mui/material";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import MenuItem from "@mui/material/MenuItem";

import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import bluelogo from "../Assets/VBS logo-01_Org.png";
import { navItems } from "./Header";
import { Link, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
const ResponsiveHeader = () => {
  const location = useLocation();
  const [openDrawer, setopenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setopenDrawer(false);
  };
  const [menucolor, setMenucolor] = React.useState(
    location.pathname === "/" ? `#1E1666` : "white"
  );

  React.useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // console.log(window.scrollY, "scrolly");
      if (window.scrollY > 0) {
        setMenucolor("#1E1666");
      } else {
        setMenucolor(window.location.pathname === "/" ? "#1E1666 " : "white ");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    setMenucolor(location.pathname === "/" ? "#1E1666 " : "white");
  }, [location.pathname]);

  function refreshPage() {
    window.location.href = "/";
  }
  const navigate = useNavigate();

  return (
    <div>
      <IconButton
        onClick={() => setopenDrawer(!openDrawer)}
        sx={{ float: "right" }}
        aria-label="menu"
      >
        <MenuIcon sx={{ color: menucolor, float: "right" }} />
      </IconButton>

      <Drawer
        open={openDrawer}
        onClose={() => setopenDrawer(false)}
        PaperProps={{
          sx: {
            width: "250px",
            color: "black",
          },
        }}
      >
        <List>
          <CloseIcon
            onClick={() => setopenDrawer(false)}
            sx={{ float: "right", marginRight: "10px" }}
          ></CloseIcon>

          <img
            src={bluelogo}
            width={120}
            height={"auto"}
            onClick={refreshPage}
            // style={{ margin: "auto" }}
          />

          <ListItem disablePadding>
            <ListItemButton
              sx={{ pt: 0, pb: 0 }}
              onClick={() => {
                handleClose();
                navigate("/");
              }}
            >
              <ListItemText
                primary={`Home`}
                sx={{
                  textAlign: "left",
                  color: "#1E1666",
                  fontWeight: "bolder",
                  fontFamily: "none",
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <Accordion
              sx={{
                boxShadow: "none",
                width: "100%",
                minHeight: "32px",
                margin: 0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  boxShadow: "none",
                  color: "#1E1666",
                  minHeight: "32px",

                  "&.Mui-expanded": {
                    minHeight: "15px", // Apply the provided CSS inline for expanded state
                  },
                }}
                className="menuaccordian"
              >
                <Typography>Services</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ padding: "0px !important", color: "#1E1666" }}
              >
                {dropdown.map((data, index) => (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(data.link);
                    }}
                    key={index}
                    style={{ minHeight: `0` }}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </AccordionDetails>
            </Accordion>
          </ListItem>

          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                sx={{ textAlign: "left", color: "#1E1666", pt: 0, pb: 0 }}
                onClick={() => {
                  setopenDrawer(false); // Close the drawer when a navigation item is clicked
                  navigate(item.link); // Navigate to the specified route
                }}
              >
                <ListItemText
                  primary={item.name}
                  // onClick={() => setopenDrawer(false),
                  //   navigate(item.link)}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default ResponsiveHeader;

export const dropdown = [
  { link: "/seo", name: "SEO" },
  { link: "/web-development", name: "Web Development" },
  { link: "/Presentation-design", name: "Presentation Design" },
  { link: "/digital-marketing", name: "Digital Marketing" },
  { link: "/graphics-design", name: "Graphics Design" },
  { link: "/mobile-app-development", name: "Mobile App Development" },
];
