import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TypographyText } from "../ReusableComponent/Reusab";
import logo from "../Assets/VBS logo-04_Org.png";
import logo1 from "../Assets/VBS Inner Page Footer.png";

import { Link, useLocation } from "react-router-dom";
const Foter = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const footerlink = [
    {
      txt: <>About Us</>,
      data: [
        {
          text1: (
            <>
              <br />
              Empowering businesses with cutting-edge digital solutions and
              compelling narratives for growth in the digital landscape.
              <br />
              <br />
            </>
          ),
        },
      ],
    },
    {
      txt: <>Important Links</>,
      data: [
        {
          text1: (
            <>
              <br /> <Link to="/">Home</Link>
            </>
          ),
        },
        { text1: <Link to={"/aboutus"}>About Us</Link> },
        { text1: <Link to={"/freequote"}>Free Quote</Link> },
        { text1: <Link to={"/faq"}>FAQ</Link> },
        {
          text1: (
            <>
              {" "}
              <Link to={"/contactus"}>Contact Us</Link>
              <br /> <br /> <br />
            </>
          ),
        },
      ],
    },
    {
      txt: <>Featured Services</>,
      data: [
        {
          text1: (
            <>
              {" "}
              <Link to={"/seo"}>SEO</Link>
            </>
          ),
        },
        { text1: <Link to={"/web-development"}>Web Development</Link> },
        { text1: <Link to={"/Presentation-design"}>Presentation Design</Link> },
    
        { text1: <Link to={"/digital-marketing"}>Digital Marketing</Link> },
        { text1: <Link to={"/graphics-design"}>Graphics Design</Link> },
        {
          text1: (
            <>
              <Link to={"/mobile-app-development"}>Mobile App Development</Link>
              <br />
            </>
          ),
        },
      ],
    },
    {
      txt: <>Contact Us</>,
      data: [
        {
          text1: (
            <List>
              <ListItem sx={{ paddingLeft: 0 }}>
                <img
                  src="https://ik.imagekit.io/41cnxfw1v/vbsimages/download.png?updatedAt=1712850247801"
                  alt="flag_img"
                  width={25}
                  height={18}
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                Office #9, Park Center,
                <br />
                Technopark, Trivandrum, <br />
                Kerala ,India <br />
                +91 892189 0865
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <img
                  src="https://ik.imagekit.io/41cnxfw1v/vbsimages/download%20(1).png?updatedAt=1712850230030"
                  alt="flag"
                  width={25}
                  height={18}
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                Office 215, B62, Riggat Al Buteen,
                <br />
                Deira, Dubai ,United Arab Emirates
                <br /> +971 58 567 2042
              </ListItem>
              <br />
            </List>
          ),
        },
      ],
    },
  ];

  return (
    <div>
      <Grid
        container spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundSize: "100% 100%", 
          backgroundPositionX: "60%",
          backgroundImage:
            location.pathname === "/"
              ? isSmallScreen
                ? `url(https://ik.imagekit.io/41cnxfw1v/vbsimages/Home/footer-bg-1.jpg?updatedAt=1712840827284)`
                : "url(https://ik.imagekit.io/41cnxfw1v/vbsimages/Home/footer-bg.jpg?updatedAt=1712811583581)"
              : "none",
          backgroundColor:
            location.pathname === "/" ? "transparent" : "#332295",
          backgroundPositionY: "100%",
          pl: "8%",
          pr: "8%",
          pt: location.pathname === "/" ? "20%" : "8%",
          pb: "3%",
          textAlign: "left",
          backgroundRepeat: isSmallScreen ? "no-repeat" : "no-repeat",
           objectFit:'cover !imprtant'
        }}
      >
        {footerlink.map((post, index) => (
          <Grid
            item
            lg={index === 1 ? 2.5 : index === 3 ? 3.5 : 3}
            md={3}
            sm={6}
            xs={12}
            key={index}
            sx={{ alignSelf: "flex-start" }}
          >
            <TypographyText
              Typography={post.txt}
              fontWeight="600"
              fontSize={"1.25rem"}
              color={location.pathname === "/" ? "black" : "white"}
              textAlign="left"
            />
            <List sx={{ textAlign: "left" }}>
              {post.data.map((datas, indexs) => (
                <ListItem
                  sx={{ textAlign: "left", padding: "0 !important" }}
                  key={indexs}
                >
                  <ListItemText sx={{ textAlign: "left", padding: "0" }}>
                    <TypographyText
                      Typography={datas.text1}
                      color={location.pathname === "/" ? "black" : "white"}
                    />
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            {index === 0 ? (
              <img
                src={location.pathname === "/" ? logo : logo1}
                width={120}
                height={"auto"}
                alt="logo_footer"
              />
            ) : (
              ""
            )}
          </Grid>
        ))}
        <Grid
          item
          lg={12}
          sm={12}
          xs={12}
          md={12}
          sx={{ margin: "auto", textAlign: "center" }}
        >
          <hr />
          <TypographyText
            Typography="Copyright 2018-2024 © Vedaham Business Solutions Pvt Ltd."
            textAlign="centre"
            color={location.pathname === "/" ? "black" : "white"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Foter;
