import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  CardsSection,
  MetaComponent,
  Section2,
  TypographyText,
} from "../ReusableComponent/Reusab";
import { Box, Grid, ListItemText } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

const Socialmedia = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <MetaComponent 
       dynamicTitle="Graphic Design Agency in Dubai | Graphic Designing in UAE" 
       dynamicKeywords="Graphic Design Agency in Dubai | Graphic Designing in UAE"
       dynamicDescription="Vedaham Business Solutions is one of the top Graphic Designing Company in UAE. Here we create the designs with a Brand Identity to stand out the Client's Business among competitors." /> 
       
      <CardsSection
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/banner-bg.png?updatedAt=1712842485552"
        txt1="
        Graphic Design Services"
        txt2="
        At Vedaham Business Solutions, we specialize in crafting captivating graphic designs that elevate brand identities with meticulous attention to detail.​"
        imges="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/design-process-landing-page-concept_23-2148319533-removebg-preview.9c32f290ff189a6a0b24.png?updatedAt=1712847647009"
      />

      <Section2
        pt="5%"
        backgroundImage="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/social-media-marketing-network-banner.png?updatedAt=1712847890694"
        text1="Crafting Visual Stories"
        text2={
          <>
            Unlock the power of compelling visuals with our graphic design
            expertise. From captivating logos to stunning promotional materials,
            we specialize in crafting designs that resonate with your audience
            and leave a lasting impression. Our team of skilled designers
            combines creativity with strategy to deliver designs that not only
            look great but also drive results.
          </>
        }
      />
      <Grid
        container
        spacing={2}
        sx={{
          p: "6%",
          backgroundImage:
            "url(https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/social-media-marketing-curve-banner.png?updatedAt=1712847649764)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/set-graphic-design-elements-workspace-collection-architect-designer-tools-objects-icons-set-instruments-creative-drawing-idea-new-art-business-concept-flat-vector-illustration_345238-10.png?updatedAt=1712847646973"
            alt="art"
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Grid container spacing={2}>
            {Listcontent2.map((data, index) => (
              <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
                {!isSmallScreen ? (
                  <ListItem disablePadding>
                    <ListItemButton
                      style={{ backgroundColor: "#fff", padding: "5%" }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                    >
                      <ListItemIcon>
                        <img src={data.listimg} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <TypographyText
                            Typography={data.listtxt}
                            color="#1E1666"
                            fontSize="20px"
                            fontWeight="600"
                          />
                        }
                        secondary={
                          <TypographyText
                            Typography={data.listtxt1}
                            color="#6A7C92"
                          />
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <card>
                    <img src={data.listimg} alt="" width="60px" />
                    <br />
                    <TypographyText
                      Typography={data.listtxt}
                      color="#1E1666"
                      fontSize="20px"
                      fontWeight="600"
                    />

                    <TypographyText
                      Typography={data.listtxt1}
                      color="#6A7C92"
                    />
                  </card>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: "auto" }}>
          <Section2
            pt="5%"
            text1="Our Graphic Design Services Include:"
            text2={
              <>
                Transform your brand's visual presence with our comprehensive
                graphic design services. From crafting memorable logos to
                creating cohesive branding collaterals, elevating marketing
                materials, and enhancing digital assets, we specialize in
                bringing your brand to life across various platforms with
                creativity and precision.
              </>
            }
          />
        </Grid>
        {Listcontent3.map((data, index) => (
          <Grid item lg={6} md={6} xs={12} sm={12} key={index}>
            {!isSmallScreen ? (
              <ListItem disablePadding>
                <ListItemButton
                  style={{ backgroundColor: "transparent", padding: "5%" }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                >
                  <ListItemIcon>
                    <img src={data.listimg} alt="" width="100px" />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <TypographyText
                        Typography={data.listtxt}
                        color="#1E1666"
                        fontSize="20px"
                        fontWeight="600"
                      />
                    }
                    secondary={
                      <TypographyText
                        Typography={data.listtxt1}
                        color="#6A7C92"
                      />
                    }
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <card>
                <img src={data.listimg} alt="" width="150px" />
                <br />
                <TypographyText
                  Typography={data.listtxt}
                  color="#1E1666"
                  fontSize="20px"
                  fontWeight="600"
                />

                <TypographyText Typography={data.listtxt1} color="#6A7C92" />
              </card>
            )}
          </Grid>
        ))}
        <Grid item lg={9} md={9} xs={12} sm={12}>
          <Grid container spacing={2}>
            <br />
            <Grid item xs={12}>
              <TypographyText
                Typography={<>Transform Your Vision into Reality</>}
                color="#1E1666"
                fontSize="30px"
                fontWeight="600"
                textAlign={!isSmallScreen ? "left" : "centre"}
              />
            </Grid>
            <Grid item xs={12}>
              <hr
                width="50px;"
                color="red"
                size="5"
                style={{ margin: !isSmallScreen ? 0 : "auto" }}
              ></hr>
            </Grid>
            <Grid item xs={12}>
              <br />
              <TypographyText
                Typography={
                  <>
                    Ready to take your brand to the next level? Partner with
                    Vedaham Business Solutions for graphic design services that
                    exceed expectations. Let's collaborate to bring your vision
                    to life and make a lasting impact in the digital realm.
                  </>
                }
                color="#6A7C92"
                textAlign="left"
              />
              <br />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: "auto" }}>
          <img
            src="https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/design-process-concept-landing-page_23-2148324107.avif?updatedAt=1712847646239"
            alt=""
            width="80%"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Socialmedia;

const Listcontent2 = [
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/link-building-circle-icon1.jpg?updatedAt=1712847646406",
    listtxt: "Creative Excellence",
    listtxt1:
      "Our designers are masters at thinking outside the box and pushing the boundaries of creativity to deliver designs that stand out.",
  },

  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/link-building-circle-icon2.jpg?updatedAt=1712847646408",
    listtxt: "Strategic Approach",
    listtxt1:
      "We don't just create visually appealing designs; we ensure that each design element serves a purpose and aligns with your brand's objectives.",
  },
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/link-building-circle-icon3.jpg?updatedAt=1712847646283",
    listtxt: "Customized Solutions",
    listtxt1:
      "Whether you need a complete brand overhaul or a single design project, we tailor our services to meet your unique requirements.",
  },
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/link-building-circle-icon1.jpg?updatedAt=1712847646406",
    listtxt: "Timely Delivery",
    listtxt1:
      "We understand the importance of deadlines. Rest assured, your projects will be delivered promptly without compromising on quality.",
  },
];

const Listcontent3 = [
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/logo-design-company-slogan-creating-corporate-branding-identity-graphic-designer-flat-character-researching-competitive-logotype-idea_335657-2630.avif?updatedAt=1712847646987",
    listtxt: "Logo Design",
    listtxt1:
      "It is a long established fact that a reader will betracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsumosed.",
  },

  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/colorful-overloaded-bullet-journal_23-2150248203.avif?updatedAt=1712847646025",
    listtxt: "Branding Collaterals",
    listtxt1:
      "From business cards to letterheads, we create cohesive branding materials that leave a lasting impression.",
  },
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/marketing-elements-collection_1040-631.avif?updatedAt=1712847646976",
    listtxt: "Marketing Materials",
    listtxt1:
      "Elevate your marketing efforts with eye-catching flyers, brochures, and posters designed to attract attention and drive engagement.",
  },
  {
    listimg:
      "https://ik.imagekit.io/41cnxfw1v/vbsimages/services-graphicdesign/isometric-nft-illustration_23-2148950467.avif?updatedAt=1712847647076",
    listtxt: "Digital Assets",
    listtxt1:
      "Enhance your online presence with captivating social media graphics, website banners, and email newsletters.",
  },
];
